import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import profile from "../images/profile.png";
import classes from './result.module.css';
import MyPDF from "./MyPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { UserContext } from "../context/context";

const Result = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { results, name, age, gender, pic } = location.state || {}; // Get data from state
    console.log("Results in result page",results)
    const { currGen, setCurrGen } = useContext(UserContext);
    const [currentDate, setCurrentDate] = useState();

    const setColor = (color) => {
        document.documentElement.style.setProperty('--cbg1', color); // Updates globally
    }
    
      useEffect(() => {
        console.log('useEffect hook')
        if (currGen?.currGender && currGen.currGender!=='Male') {
          console.log('currGen ---> F')
          setColor('#F8E6EA')
        } else {
          console.log('currGen ---> M')
          setColor('#E8F4F6')
        }
    }, [currGen])

    const formatDate = (date) => {
        const options = { month: 'short', year: 'numeric' };
        const day = date.getDate();
        const month = date.toLocaleDateString('en-US', options).split(' ')[0]; // Get the short month name
        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // Catch 11th-13th
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
        return `${day}${suffix(day)} ${month} ${date.getFullYear()}`;
    };

    useEffect(()=> {

        setCurrentDate(formatDate(new Date()));
    }, [])

    const navigatePath = (path) => {
        navigate(path)
    }

    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    const displayedItems = [
        results?.skin_type && results.skin_type !== 'Normal',
        results?.dark_circles && results.dark_circles !== 'No',
        results?.skin_disease && results.skin_disease !== 'clear skin',
        results?.wrinkles && results.wrinkles === 'wrinkles',
        results?.skin_cancer
    ].filter(Boolean).length; // This will give the number of true conditions

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the scrolling speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    
        // Get the current scroll position, the container width, and child widths
        const container = scrollContainerRef.current;
        const scrollLeft = container.scrollLeft;
        const childWidth = container.scrollWidth / displayedItems;
        console.log('Scroll Left:', scrollLeft);
        console.log('Child Width:', childWidth);
        // console.log('Closest Index:', closestIndex);
    
        // // Calculate the closest index
        // const closestIndex = Math.round(scrollLeft / childWidth);
        
        // // Set the current index and scroll to the closest element
        // setCurrentIndex(closestIndex);
        // container.scrollTo({
        //     left: closestIndex * childWidth,
        //     behavior: 'smooth',
        // });
    };

    useEffect(() => {
        const handleScroll = () => {
            const container = scrollContainerRef.current;
            const scrollLeft = container.scrollLeft;
            const childWidth = container.scrollWidth / displayedItems;
    
            // Update current index as user scrolls
            const newIndex = Math.round(scrollLeft / childWidth);
            setCurrentIndex(newIndex);
        };
    
        const container = scrollContainerRef.current;
        container.addEventListener('scroll', handleScroll);
    
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [displayedItems]);

    // Function to scroll to the selected index
    const scrollToIndex = (index) => {
        const container = scrollContainerRef.current;
        const childWidth = container.scrollWidth / displayedItems;
        container.scrollTo({
            left: index * childWidth,
            behavior: 'smooth',
        });
    };


    return (
        <div className="h-fit w-full">
            {/* D E S K T O P  &  T A B */}
            <div className="h-screen min-h-fit w-full flex xxs:hidden tabPotrait:flex flex-col justify-start items-center bg-cbg1 pc:bg-white transition-all duration-500 ease-in-out">
                <div className="h-28 w-full px-10 pt-5 flex flex-row justify-between">
                    <img src={logo} alt="logo" className="h-12 flex" />
                    <div className="flex flex-row h-12 items-center select-none text-lg">
                        <div className="px-10 font-medium cursor-pointer" onClick={() => navigatePath('/home')}>Home</div>
                        <div className="px-10 font-medium cursor-pointer" onClick={() => navigatePath('/test')}>Take Test</div>
                        <div className="px-10 font-medium cursor-pointer" onClick={() => navigatePath('/contact')}>Contact Us</div>
                    </div>
                    <img src={profile} alt="profile" className="h-8 mx-10 cursor-pointer" onClick={() => navigatePath('/profile')}/>
                </div>
                <div className="min-h-hit w-[92%] py-3 px-8 flex flex-row justify-between items-center text-white text-2xl bg-cbg2 rounded-xl shadow-cs1">
                    <div>Skin Test Results</div>
                    <PDFDownloadLink
                        document={
                            <MyPDF
                                result={results}
                                name={name}
                                age={age}
                                gender={gender}
                                date={currentDate}
                            />
                        }
                        fileName={`${name}_pdf`}
                        className="w-36 px-2 py-1 flex flex-row justify-between items-center rounded-md text-cbg2 hover:text-white bg-white hover:bg-cbg2 
                            text-lg tracking-wider border border-white group transition duration-300 ease-in-out hover:shadow-csWhite select-none
                            focus:outline-none focus:text-white focus:bg-cbg2 focus:shadow-csWhite"
                    >
                        
                        <svg className="h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" 
                                    className="stroke-current group-hover:stroke-white" 
                                    strokeWidth="1.5" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round">
                                </path>
                                <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" 
                                    className="stroke-current group-hover:stroke-white" 
                                    strokeWidth="1.5" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round">
                                </path>
                            </g>
                        </svg>
                        Download
                    </PDFDownloadLink>
                </div>
                {/* <div className="h-8 w-[92%] border-b border-black border-dashed"></div> */}
                <div className="min-h-hit w-[92%] mt-9 mb-10 py-5 px-10 gap-10 flex flex-col justify-between items-center bg-cbg1 pc:rounded-xl pc pc:shadow-cs1 border-t border-gray-500 pc:border-none transition-all duration-500 ease-in-out">
                    <div className="w-full flex flex-row justify-start">
                        <div className="flex flex-row px-5 text-lg"><div className="font-medium">Name -</div>&nbsp;{name}</div>
                        <div className="flex flex-row px-5 text-lg"><div className="font-medium">Age -</div>&nbsp;{age}</div>
                        <div className="flex flex-row px-5 text-lg"><div className="font-medium">Gender -</div>&nbsp;{gender}</div>
                        <div className="flex flex-row px-5 text-lg"><div className="font-medium">Date -</div>&nbsp;{currentDate}</div>
                    </div>
                    <div className="w-full flex flex-row justify-between text-base tab:text-lg">
                        <div className="w-[22%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">FACE SHAPE - {results?.face_shape}</div>
                        <div className="w-[22%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">DARK CIRCLES - {results?.dark_circles}</div>
                        <div className="w-[22%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">SKIN TONE - {results?.skin_tone}</div>
                        <div className="w-[22%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">SKIN TYPE - {results?.skin_type}</div>
                    </div>
                    <div className="w-full min-h-fit flex flex-row justify-evenly mt-8">
                        <div className="relative min-w-fit w-[20%] m-3 p-3">
                            <div className="relative w-full h-full rounded-full shadow-cs1">
                                <img src={pic} className="w-full rounded-full z-10 p-2 relative" />
                                <div className="absolute top-0 left-0 w-full h-full rounded-full z-0 bg-white"></div>
                            </div>
                        </div>
                        <div className="w-[50%] min-h-fit gap-2 flex flex-col justify-evenly items-center">
                            {results?.skin_type && results.skin_type==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Type
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.skin_type.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.skin_tone && results.skin_tone==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Tone
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.skin_tone.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.face_shape && results.face_shape==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Face Shape
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.face_shape.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.dark_circles && results.dark_circles==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Dark Circles
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.dark_circles.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}


                            {/* T O   S H O W */}
                            {results?.skin_disease && results.skin_disease!=="clear skin" ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Disease
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.skin_disease.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Disease
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        No Disease
                                    </div>
                                </div>
                            }
                            {results?.wrinkles && results.wrinkles!=='No' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Wrinkles
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.wrinkles==='wrinkles' ? 'Yes' : 'No'}
                                    </div>
                                </div>
                            ) : null}
                            {results?.eye_shape && results.eye_shape!=='No' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Eye Shape
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.eye_shape.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.hair_type && results.hair_type!=='No' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Hair Type
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.hair_type.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            <div onClick={() => navigatePath('/test')} className="cursor-pointer text-tbg2 font-bold tracking-wider">
                                CHECK ANOTHER RESULT
                            </div>
                        </div>
                    </div>
                    {results?.skin_type && results.skin_type!=='Normal' ? (
                        <div className="h-fit w-full rounded-lg shadow-cs1">
                            <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                <div>Skin Type</div>
                                <div className="shadow-cs2 px-2 rounded-md">{results.skin_type} Skin</div>
                            </div>
                            <div className="w-full h-fit font-medium px-5 py-3">
                                Prevention & Maintenance :
                                <ul className="font-normal list-disc px-5 leading-7">
                                    <li className="pt-2">Keep your skin hydrated by drinking enough water daily.</li>
                                    <li>Use a gentle cleanser to maintain the skin’s natural balance.</li>
                                    <li>Always wear sunscreen to protect from UV damage.</li>
                                    <li>Apply a light moisturizer to keep the skin smooth and supple.</li>
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {results?.dark_circles && results.dark_circles!=='No' ? (
                        <div className="h-fit w-full rounded-lg shadow-cs1">
                            <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                <div>Dark Circles</div>
                                {results.dark_circles==='No' ? (<div className="shadow-cs2 px-2 rounded-md">Not Detected</div>) : (<div className="shadow-cs2 px-2 rounded-md">Detected</div>)}
                            </div>
                            <div className="w-full h-fit font-medium px-5 py-3">
                                Causes :
                                <ul className="flex flex-row gap-10 font-normal list-disc px-5 leading-7">
                                    <li>Lack of sleep</li>
                                    <li>Stress and fatigue</li>
                                    <li>Dehydration</li>
                                    <li>Excessive sun exposure</li>
                                </ul>
                            </div>
                            <div className="w-full h-fit font-medium px-5 py-3">
                                Prevention & Maintenance :
                                <ul className="font-normal list-disc px-5 leading-7">
                                    <li className="pt-2">Get 7-8 hours of quality sleep.</li>
                                    <li>Stay hydrated by drinking plenty of water.</li>
                                    <li>Apply a cooling eye cream with ingredients like hyaluronic acid or vitamin C.</li>
                                    <li>Use sunscreen and sunglasses to protect the under-eye area from UV rays.</li>
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {results?.skin_disease && results.skin_disease!=='clear skin' ? (
                        <div className="h-fit w-full rounded-lg shadow-cs1">
                            <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                <div>Skin Disease</div>
                                <div className="shadow-cs2 px-2 rounded-md">{results.skin_disease.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div>
                            </div>
                            <div className="w-full h-fit font-medium px-5 py-3">
                                Causes :
                                <ul className="flex flex-row gap-10 font-normal list-disc px-5 leading-7">
                                    <li>Poor diet</li>
                                    <li>Bacterial buildup</li>
                                    <li>Excess oil (sebum) production</li>
                                    <li>Hormonal changes</li>
                                </ul>
                            </div>
                            <div className="w-full h-fit font-medium px-5 py-3">
                                Prevention & Maintenance :
                                <ul className="font-normal list-disc px-5 leading-7">
                                    <li className="pt-2">Use oil-free or non-comedogenic skincare and makeup products.</li>
                                    <li>Maintain a balanced diet rich in fruits, vegetables, and water to support skin health.</li>
                                    <li>Avoid touching your face to reduce bacterial transfer.</li>
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {results?.wrinkles && results.wrinkles!=='no_wrinkle' && results.wrinkles!=='No_wrinkles_detected' ? (
                    <div className="h-fit w-full rounded-lg shadow-cs1">
                        <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                            <div>Wrinkles</div>
                            <div className="shadow-cs2 px-2 rounded-md">Detected</div>
                        </div>
                        <div className="w-full h-fit font-medium px-5 py-3">
                            Causes :
                            <ul className="flex flex-row gap-10 font-normal list-disc px-5 leading-7">
                            <li>Sun exposure</li>
                            <li>Dehydration</li>
                            <li>Aging and loss of skin elasticity</li>
                            <li>Smoking</li>
                            </ul>
                        </div>
                        <div className="w-full h-fit font-medium px-5 py-3">
                            Prevention & Maintenance :
                            <ul className="font-normal list-disc px-5 leading-7">
                            <li className="pt-2">Use sunscreen daily to protect from UV damage.</li>
                            <li>Moisturize regularly with products containing retinol or hyaluronic acid.</li>
                            <li>Stay hydrated by drinking enough water.</li>
                            <li>Avoid smoking, as it accelerates skin aging.</li>
                            </ul>
                        </div>
                    </div>
                    ) : null}
                    {results?.skin_cancer ? (
                        <div className="h-fit w-full rounded-lg shadow-cs1">
                            <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                <div>Skin Cancer</div>
                                {/* <div className="shadow-cs2 px-2">{results.skin_cancer.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div> */}
                            </div>
                            <div className="w-full h-fit font-medium px-5 py-3">
                                Causes :
                                <ul className="flex flex-row gap-10 font-normal list-disc px-5 leading-7">
                                <li>UV Exposure</li>
                                <li>Genetics</li>
                                <li>Fair Skin</li>
                                <li>Moles</li>
                                </ul>
                            </div>
                            <div className="w-full h-fit font-medium px-5 py-3">
                                Prevention & Maintenance :
                                <ul className="font-normal list-disc px-5 leading-7">
                                <li className="pt-2">Avoid Tanning Beds: Stay away from artificial UV sources like tanning beds.</li>
                                <li>Regular Skin Checks: Perform monthly self-examinations to spot any new or changing moles, and visit a dermatologist annually for professional screenings.</li>
                                <li>Seek Shade: Avoid direct sunlight during peak hours.</li>
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* M O B I L E */}
            <div className="h-screen min-h-fit w-full flex tabPotrait:hidden flex-col justify-start items-center bg-cbg1 pc:bg-white">
                <div className="h-20 w-full px-3 py-3 md:py-2 flex flex-row justify-between items-center bg-white">
                    <img src={logo} alt="logo" className="h-10 select-none" />
                    <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/home')}>Home</div>
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/test')}>Test</div>
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/contact')}>Contact Us</div>
                    </div>
                    <img src={profile} alt="profile" className="h-8 mx-6 cursor-pointer" onClick={() => navigatePath('/profile')}/>
                </div>
                <div className="min-h-hit w-[92%] mt-10 py-3 px-8 flex flex-row justify-center items-center text-white text-2xl bg-cbg2 rounded-xl shadow-cs1">
                    <div>Skin Test Results</div>
                </div>
                {/* <div className="h-8 w-[92%] border-b border-black border-dashed"></div> */}
                <div className="min-h-hit w-[92%] mt-5 mb-10 py-5 gap-5 flex flex-col justify-between items-center bg-cbg1 pc:rounded-xl pc:shadow-cs1 border-t border-gray-500 pc:border-none">
                    <PDFDownloadLink
                        document={
                            <MyPDF
                                result={results}
                                name={name}
                                age={age}
                                gender={gender}
                                date={currentDate}
                            />
                        }
                        fileName={`${name}_pdf`}
                        className="w-36 px-2 py-1 mr-auto flex flex-row justify-between items-center rounded-md text-cbg2 hover:text-white bg-white 
                            hover:bg-cbg2 text-lg tracking-wider group transition duration-300 ease-in-out 
                            select-none focus:outline-none focus:text-white focus:bg-cbg2 shadow-cs1"
                    >
                        <svg className="h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" 
                                    className="stroke-current group-hover:stroke-white" 
                                    strokeWidth="1.5" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round">
                                </path>
                                <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" 
                                    className="stroke-current group-hover:stroke-white" 
                                    strokeWidth="1.5" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round">
                                </path>
                            </g>
                        </svg>
                        Download
                    </PDFDownloadLink>
                    <div className="w-full flex flex-col justify-start">
                        <div className="flex flex-row text-lg"><div className="font-medium">Name -</div>&nbsp;{name}</div>
                        <div className="flex flex-row text-lg"><div className="font-medium">Age -</div>&nbsp;{age}</div>
                        <div className="flex flex-row text-lg"><div className="font-medium">Gender -</div>&nbsp;{gender}</div>
                        <div className="flex flex-row text-lg"><div className="font-medium">Date -</div>&nbsp;{currentDate}</div>
                    </div>
                    <div className="w-full flex flex-row justify-between text-sm tab:text-lg">
                        <div className="w-[44%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">FACE SHAPE - {results?.face_shape}</div>
                        <div className="w-[44%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">DARK CIRCLES -{results?.dark_circles} </div>
                    </div>
                    <div className="w-full flex flex-row justify-between text-sm tab:text-lg">
                        <div className="w-[44%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">SKIN TONE - {results?.skin_tone}</div>
                        <div className="w-[44%] py-2 flex flex-row items-center justify-center bg-white rounded-md shadow-cs2 font-medium">SKIN TYPE - {results?.skin_type}</div>
                    </div>
                    <div className="w-full min-h-fit flex flex-col justify-evenly items-center">
                        <div className="relative w-[50%] m-3 p-3 mr-auto">
                            {pic!==null && pic.length!==6 ? (
                                <div className="relative w-full h-full rounded-full shadow-cs1">
                                    <img src={pic} className="w-full rounded-full z-10 p-2 relative" />
                                    <div className="absolute top-0 left-0 w-full h-full rounded-full z-0 bg-white"></div>
                                </div>
                            ) : null}
                        </div>
                        <div className="w-full min-h-fit gap-5 flex flex-col justify-evenly items-center">
                            {results?.skin_type && results.skin_type==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Type
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.skin_type.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.skin_tone && results.skin_tone==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Tone
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.skin_tone.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.face_shape && results.face_shape==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Face Shape
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.face_shape.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.dark_circles && results.dark_circles==='' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Dark Circles
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.dark_circles.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}


                            {/* T O   S H O W */}
                            {results?.skin_disease && results.skin_disease!=="clear skin" ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Disease
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.skin_disease.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Skin Disease
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        No Disease
                                    </div>
                                </div>
                            }
                            {results?.wrinkles && results.wrinkles!=='No' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Wrinkles
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                    {results.wrinkles==='wrinkles' ? 'Yes' : 'No'}
                                    </div>
                                </div>
                            ) : null}
                             {results?.eye_shape && results.eye_shape!=='No' ? (
                                <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Eye Shape
                                    <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                        {results.eye_shape.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </div>
                                </div>
                            ) : null}
                            {results?.hair_type && results.hair_type!=='No' ? (
                               <div className="w-full min-h-fit px-4 py-2 flex flex-row justify-between items-center bg-white rounded-md text-lg">Hair Type
                                   <div className="min-w-fit w-32 pc:w-40 min-h-fit text-center text-base font-medium py-1 rounded-md shadow-cs2 bg-cbg1">
                                       {results.hair_type.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                   </div>
                               </div>
                           ) : null}
                            <div onClick={() => navigatePath('/test')} className="cursor-pointer text-tbg2 font-bold tracking-wider">
                                CHECK ANOTHER RESULT
                            </div>
                        </div>
                    </div>
                    <div 
                        className={`w-full h-fit px-2 py-2 gap-5 flex flex-row justify-start overflow-x-auto snap-x snap-mandatory select-none ${classes.scrollContainer}`}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        ref={scrollContainerRef}
                        style={{ cursor: isDragging ? 'grabbing' : 'grab', scrollBehavior: 'smooth', }}
                    >
                        {results?.skin_type && results.skin_type !== 'Normal' ? (
                            <div className="h-full w-full flex flex-col flex-shrink-0 rounded-lg shadow-cs1 snap-center">
                                <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                    <div>Skin Type</div>
                                    <div className="shadow-cs2 px-2 rounded-md">{results.skin_type} Skin</div>
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Prevention & Maintenance:
                                    <ul className="font-normal list-disc px-5 leading-7">
                                        <li className="pt-2">Keep your skin hydrated by drinking enough water daily.</li>
                                        <li>Use a gentle cleanser to maintain the skin’s natural balance.</li>
                                        <li>Always wear sunscreen to protect from UV damage.</li>
                                        <li>Apply a light moisturizer to keep the skin smooth and supple.</li>
                                    </ul>
                                </div>
                            </div>
                        ) : null}
                        {results?.dark_circles && results.dark_circles!=='No' ? (
                            <div className="h-full w-full flex flex-col flex-shrink-0 rounded-lg shadow-cs1 snap-center">
                                <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                    <div>Dark Circles</div>
                                    {results.dark_circles==='No' ? (<div className="shadow-cs2 px-2 rounded-md">Not Detected</div>) : (<div className="shadow-cs2 px-2 rounded-md">Detected</div>)}
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Causes :
                                    <ul className="flex flex-col font-normal list-disc px-5 leading-7">
                                        <li>Lack of sleep</li>
                                        <li>Stress and fatigue</li>
                                        <li>Dehydration</li>
                                        <li>Excessive sun exposure</li>
                                    </ul>
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Prevention & Maintenance :
                                    <ul className="font-normal list-disc px-5 leading-7">
                                        <li className="pt-2">Get 7-8 hours of quality sleep.</li>
                                        <li>Stay hydrated by drinking plenty of water.</li>
                                        <li>Apply a cooling eye cream with ingredients like hyaluronic acid or vitamin C.</li>
                                        <li>Use sunscreen and sunglasses to protect the under-eye area from UV rays.</li>
                                    </ul>
                                </div>
                            </div>
                        ) : null}
                        {results?.skin_disease && results.skin_disease!=='clear skin' ? (
                            <div className="h-full w-full flex flex-col flex-shrink-0 rounded-lg shadow-cs1 snap-center">
                                <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                    <div>Skin Disease</div>
                                    <div className="shadow-cs2 px-2 rounded-md">{results.skin_disease.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div>
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Causes :
                                    <ul className="flex flex-col font-normal list-disc px-5 leading-7">
                                        <li>Poor diet</li>
                                        <li>Bacterial buildup</li>
                                        <li>Excess oil (sebum) production</li>
                                        <li>Hormonal changes</li>
                                    </ul>
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Prevention & Maintenance :
                                    <ul className="font-normal list-disc px-5 leading-7">
                                        <li className="pt-2">Use oil-free or non-comedogenic skincare and makeup products.</li>
                                        <li>Maintain a balanced diet rich in fruits, vegetables, and water to support skin health.</li>
                                        <li>Avoid touching your face to reduce bacterial transfer.</li>
                                    </ul>
                                </div>
                            </div>
                        ) : null}
                        {results?.wrinkles && results.wrinkles!=='no_wrinkle' && results.wrinkles!=='No_wrinkles_detected' ? (
                            <div className="h-full w-full flex flex-col flex-shrink-0 rounded-lg shadow-cs1 snap-center">
                                <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                    <div>Wrinkles</div>
                                    <div className="shadow-cs2 px-2 rounded-md">Detected</div>
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Causes :
                                    <ul className="flex flex-col font-normal list-disc px-5 leading-7">
                                    <li>Sun exposure</li>
                                    <li>Dehydration</li>
                                    <li>Aging and loss of skin elasticity</li>
                                    <li>Smoking</li>
                                    </ul>
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Prevention & Maintenance :
                                    <ul className="font-normal list-disc px-5 leading-7">
                                    <li className="pt-2">Use sunscreen daily to protect from UV damage.</li>
                                    <li>Moisturize regularly with products containing retinol or hyaluronic acid.</li>
                                    <li>Stay hydrated by drinking enough water.</li>
                                    <li>Avoid smoking, as it accelerates skin aging.</li>
                                    </ul>
                                </div>
                            </div>
                        ) : null}
                        {results?.skin_cancer ? (
                            <div className="h-full w-full flex flex-col flex-shrink-0 rounded-lg shadow-cs1 snap-center">
                                <div className="w-full h-fit px-5 py-2 flex flex-row justify-between bg-white rounded-t-lg text-lg">
                                    <div>Skin Cancer</div>
                                    {/* <div className="shadow-cs2 px-2">{results.skin_cancer.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div> */}
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Causes :
                                    <ul className="flex flex-col font-normal list-disc px-5 leading-7">
                                    <li>UV Exposure</li>
                                    <li>Genetics</li>
                                    <li>Fair Skin</li>
                                    <li>Moles</li>
                                    </ul>
                                </div>
                                <div className="w-full h-fit font-medium px-5 py-3">
                                    Prevention & Maintenance :
                                    <ul className="font-normal list-disc px-5 leading-7">
                                    <li className="pt-2">Avoid Tanning Beds: Stay away from artificial UV sources like tanning beds.</li>
                                    <li>Regular Skin Checks: Perform monthly self-examinations to spot any new or changing moles, and visit a dermatologist annually for professional screenings.</li>
                                    <li>Seek Shade: Avoid direct sunlight during peak hours.</li>
                                    </ul>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    {/* Dots Indicator with onClick event */}
                    <div className="flex justify-center items-center gap-2 mt-4 cursor-pointer">
                        {Array(displayedItems).fill(0).map((_, index) => (
                            <div
                                key={index}
                                className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-cbg2' : 'bg-gray-400'}`}
                                onClick={() => scrollToIndex(index)} // Add onClick to each dot
                            />
                        ))}
                    </div>
                </div>
                <div className="w-full  h-10 bg-white"></div>
                <div className="w-full h-fit px-10 py-10 bg-cbg6">
                    <img src={logo} alt="logo" className="h-12" />
                    <div className="w-full mt-5 text-gray-400">
                        Dermo Iterve is an advanced AI-powered skin analysis platform that
                        provides instant insights into your skin's health. With a simple
                        10-second face video, you can detect skin conditions, monitor for
                        early signs of skin cancer, and receive personalized skincare
                        recommendations.
                    </div>
                    <div className="w-full h-fit mt-10 flex justify-center text-white">
                        <div className="w-full flex flex-row justify-between text-xl select-none">
                        <div onClick={() => navigatePath('/home')}>Home</div>
                        <div onClick={() => navigatePath('/test')}>Test</div>
                        <div onClick={() => navigatePath('/contact')}>Contact Us</div>
                        </div>
                    </div>
                    <div className="mt-10 text-center text-white">
                        Copyright © 2024 AIVOT - All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Result