import './App.css';
import Home from './pages/home.js';
import Login from './pages/Login.js'
import Test from './pages/selftest.js'
import Result from './pages/results.js';
import Register from './pages/Register.js';
import Try from './pages/trypage.js';
import Contact from './pages/contact.js';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ProfilePage from './pages/profile.js';
function Layout() {
    const location = useLocation();

    return (
      <>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/home' element={<Home />} />
          <Route path='/test' element={<Test />} />
          <Route path='/result' element={<Result />} />
          <Route path='/try' element={<Try />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/profile' element={<ProfilePage />} />


        </Routes>
      </>
    )
}

function App() {
  return (
    <>
      <Router>
        <Layout/>
      </Router>
    </>
  );
}

export default App;