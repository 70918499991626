import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import aivot from "../images/aivot.png";
import styles from './profile.module.css'; // Import the CSS module
import { UserContext } from "../context/context";
import profile from "../images/profile.png";
import logout from "../images/logout.png";
import insta from "../images/Instagram.png";
import linkedin from "../images/LinkedIn.png";
import fb from "../images/Facebook.png";
import display_pic from '../images/profile_img.png'
import axios from "axios";
import { Link } from 'react-router-dom';
 
const Profile = () => {
  const { user ,setUser} = useContext(UserContext); // Get user data from context
  const navigate = useNavigate();
 
  const [isEditMode, setIsEditMode] = useState(false); // Toggle between edit and view mode
 
  // Set initial values from context or fallback to defaults
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    password: ''
  });
 
  // Set the form data from context when the component mounts
  useEffect(() => {
    if (user) {
      const nameParts = user.name.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(' ') || '';
 
      setProfileData({
        firstName,
        lastName,
        gender: user.gender || '',
        email: user.email || '',
        password: user.password || ''
      });
    }
  }, [user]);
  const updateProfile = async () => {
    try {
      const response = await axios.put('https://dermo.iterve.ai/update_user/update', {
        email: user.email,              // Previous email (from context or state)
        newEmail: profileData.email,    // Updated email (if changed in form)
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        gender: profileData.gender,
      });
 
      const result = response.data; // Axios automatically parses the JSON response
 
      if (result.check === '200') {
        // Profile updated successfully
        console.log('Profile updated:', result.user);
        setUser((prevUser) => ({
          ...prevUser,
          name: `${profileData.firstName} ${profileData.lastName}`,
          email: profileData.email,
          gender: profileData.gender
        }));
      } else if (result.check === '400') {
        // Handle "new email already in use" error
        console.log('Error:', result.message);
      }
    } catch (error) {
      console.error('Error updating profile:', error.response ? error.response.data : error.message);
    }
  };
  // Handle change events
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value
    });
  };
 
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      updateProfile();
      // Save the data here if needed
    }
  };
 
  const navigatePath = (path) => {
    navigate(path);
  };
 
  return (
    <div className={styles.outer}>
 <div className="h-14 w-full px-3 py-3 md:py-2 flex xxs:hidden tabPotrait:flex flex-row justify-between items-center bg-white">
        <img src={aivot} alt="logo" className="h-10 select-none" />
        <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/home")}
          >
            Home
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/test")}
          >
            Test
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/contact")}
          >
            Contact Us
          </div>
        </div>
 
        <img src={profile} alt="profile" className="h-8 px-6 cursor-pointer" />
      </div>
      <div className="h-16 w-full px-3 py-3 md:py-2 flex tabPotrait:hidden flex-row justify-between items-center bg-white">
                    <img src={aivot} alt="logo" className="h-10 select-none" />
                    <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/home')}>Home</div>
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/test')}>Test</div>
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/contact')}>Contact Us</div>
                    </div>
                    <img src={profile} alt="profile" className="h-8 px-6 cursor-pointer"/>
        </div>
 
    <div className={styles.profileContainer}>
        <div className={styles.first}></div>
      <div className={styles.profileHeader}>
        <img
          src={display_pic}
          alt="User profile"
          className={styles.profileImg}
        />
        <div className={styles.profileInfo}>
          <div className={styles.head1}>{`${profileData.firstName} ${profileData.lastName}`}</div>
          <div className={styles.head2}>{profileData.email}</div>
        </div>
        <button className={styles.editBtn} onClick={toggleEditMode}>
          {isEditMode ? 'Save' : 'Edit'}
        </button>
      </div>
 
      <div className={styles.profileForm}>
        <div className={styles.formGroup}>
          <label>First Name</label>
          {isEditMode ? (
            <input
              type="text"
              name="firstName"
              value={profileData.firstName}
              onChange={handleChange}
            />
          ) : (
            <p>{profileData.firstName}</p>
          )}
        </div>
 
        <div className={styles.formGroup}>
          <label>Last Name</label>
          {isEditMode ? (
            <input
              type="text"
              name="lastName"
              value={profileData.lastName}
              onChange={handleChange}
            />
          ) : (
            <p>{profileData.lastName}</p>
          )}
        </div>
 
        <div className={styles.formGroup}>
          <label>Email</label>
          {isEditMode ? (
            <input
              type="email"
              name="email"
              value={profileData.email}
              onChange={handleChange}
            />
          ) : (
            <p>{profileData.email}</p>
          )}
        </div>
 
        <div className={styles.formGroup}>
  <label>Gender</label>
  {isEditMode ? (
    <select
      name="gender"
      value={profileData.gender}
      onChange={handleChange}
    >
      <option value="">Select Gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </select>
  ) : (
    <p>{profileData.gender}</p>
  )}
</div>
 
       <div className={styles.buttonlast}><div className={styles.logoutBtn} onClick={() => navigatePath('/')}>
          <img src={logout}alt="logoot" className={styles.logout}/>
          Logout
        </div></div>
      </div>
    </div>
    <footer className={styles.footer}>
        <div className={styles.footcontainer}>
          {/* Left Section: Logo and Description */}
          <div className={styles.leftSection}>
            <img src={aivot} alt="Aivot Logo" className={styles.logo} />
            <p className={styles.description}>
              Dermo Iterve is an advanced AI-powered skin analysis platform that
              provides instant insights into your skin's health. With a simple
              10-second face video, you can detect skin conditions, monitor for
              early signs of skin cancer, and receive personalized skincare
              recommendations.
            </p>
            <p className={styles.description2}>Follow us</p>
            <div className={styles.socialIcons}>
              <a href="#facebook" className={styles.icon}>
                <img src={fb} alt="fb" />
              </a>
              <a href="#instagram" className={styles.icon}>
                <img src={insta} alt="insta" />
              </a>
              <a href="#linkedin" className={styles.icon}>
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
 
          {/* Middle Section: Links */}
          <div className={styles.middleSection}>
            <ul className={styles.navLinks}>
            <li>
          <Link to="/home">Home</Link>
        </li>
        <li>
          <Link to="/test">Take Test</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
            </ul>
            <p className={styles.copyr}>
              Copyright &copy; 2024 AIVOT - All Rights Reserved.
            </p>
          </div>
 
          {/* Right Section: Newsletter Subscription */}
          <div className={styles.rightSection}>
            <h3 className={styles.subscribeTitle}>Subscribe to Newsletter</h3>
            <form className={styles.subscribeForm}>
              <input
                type="email"
                placeholder="Enter your email"
                className={styles.emailInput}
              />
              <button type="submit" className={styles.subscribeButton}>
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className={styles.mobilecopyr}>
          Copyright &copy; 2024 AIVOT - All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};
 
export default Profile;