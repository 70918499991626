import React from "react";

const Trypage = () => {
    const setColor = (color) => {
        document.documentElement.style.setProperty('--cbg1', color);
    }

    return (
        <div className="flex flex-col justify-center items-center h-screen w-full bg-cbg1">
            <button
                className="w-[20%] bg-cbg2 text-white m-2 p-2"
                onClick={() => setColor('#FFC0CB')} // Pink color
            >
                Pink
            </button>
            <button
                onClick={() => setColor('#0000FF')} // Blue color
                className="w-[20%] bg-cbg2 text-white m-2 p-2"
            >
                Blue
            </button>
        </div>
    );
};

export default Trypage;
