import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [currGen, setCurrGen] = useState({});

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const storedCurrGen = localStorage.getItem('currGen');
    if (storedCurrGen) {
      setCurrGen(JSON.parse(storedCurrGen));
    }
  }, []);
 
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (currGen) {
      localStorage.setItem('currGen', JSON.stringify(currGen));
    }
  }, [currGen]);
 
  return (
    <UserContext.Provider value={{ user, setUser, currGen, setCurrGen }}>
      {children}
    </UserContext.Provider>
  );
};