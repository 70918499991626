import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import logo from "../images/logo.png";
import logobg from "../images/logobgup.png";
import logobgtab from "../images/logobgupmob.png";
import logobgmob from "../images/logobgupmobile.png";
import { UserContext } from "../context/context";

const Register = () => {
  const navigate = useNavigate();
  const { user, setUser, currGen, setCurrGen } = useContext(UserContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [cnfpassword, setCnfpassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);

  const [nameMob, setNameMob] = useState("");
  const [emailMob, setEmailMob] = useState("");
  const [genderMob, setGenderMob] = useState("");
  const [numberMob, setNumberMob] = useState("");
  const [passwordMob, setPasswordMob] = useState("");
  const [cnfpasswordMob, setCnfpasswordMob] = useState("");
  const [isPasswordVisibleMob, setIsPasswordVisibleMob] = useState(false);
  const [emailValidMob, setEmailValidMob] = useState(false);
  const [passwordValidMob, setPasswordValidMob] = useState(true);

  const setColor = (color) => {
    document.documentElement.style.setProperty('--cbg1', color); // Updates globally
  }

  useEffect(() => {
    console.log('useEffect hook')
    if (user?.gender && user.gender!=='Male') {
      console.log('user ---> F')
      setColor('#F8E6EA')
    } else {
      console.log('user ---> M')
      setColor('#E8F4F6')
    }
  }, [user])

  const handleGenderChange = (e) => {
    const gen = e.target.value
    setGender(gen)
    setGenderMob(gen)
    console.log('gen', gen)
    setUser({
      gender: gen,
      currGender: gen
    })
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const email = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailPattern.test(email));
  };

  const handleContactChange = (e) => {
    const contact = e.target.value;
    setEmail(contact);  // Keep using the same state for both email and phone
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
  
    setEmailValid(emailPattern.test(contact) || phonePattern.test(contact));
  };  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // const password = e.target.value;
    // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
    // setPasswordValid(passwordPattern.test(password));
  };

  const toggleShowHide = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleEmailChangeMob = (e) => {
    setEmailMob(e.target.value);
    const email = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValidMob(emailPattern.test(email));
  };

  const handleContactChangeMob = (e) => {
    const contact = e.target.value;
    setEmailMob(contact);  // Keep using the same state for both email and phone
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
  
    setEmailValid(emailPattern.test(contact) || phonePattern.test(contact));
  };  

  const handlePasswordChangeMob = (e) => {
    setPasswordMob(e.target.value);
    // const password = e.target.value;
    // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
    // setPasswordValidMob(passwordPattern.test(password));
  };
  const toggleShowHideMob = () => {
    setIsPasswordVisibleMob((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, password, cnfpassword);
    console.log('validation', emailValid)

    if (password !== cnfpassword) {
      alert("The password confirmation does not match");
    } else if ((emailValid && passwordValid)) {
        console.log('entered')
        try {
            console.log('email ok')
          const response = await axios.post('https://dermo.iterve.ai/register_user/register', {
            name: name, 
            email: email,
            phone: '0123456789',
            username: name,
            password: password,
            gender: gender,
        });
  
          if (response.status === 200) {
            console.log(response.data);
            console.log('Request successful');
            setUser({
              name: name,
              email: email,
              phone: '0123456789',
              gender: gender,
              currGender: gender,
              password: password,
            });
            setCurrGen({
              currGender: gender
            })
  
            navigate("/home");
          }
          else if (response.status === 201) {
            alert('Email already exists')
          } else {
            console.log('Request failed', response.data.message);
          }
        } catch (error) {
          console.log('An error occurred:', error);
        }
      }
  };

  const handleSubmitMob = async (e) => {
    e.preventDefault();
    console.log(nameMob, emailMob, passwordMob, cnfpasswordMob);

    if (passwordMob !== cnfpasswordMob) {
      alert("The password confirmation does not match");
    } else if ((emailValidMob && passwordValidMob)) {
        console.log('entered')
        try {
            console.log('email ok')
          const response = await axios.post('https://dermo.iterve.ai/register_user/register', {
            name: nameMob,
            email: emailMob,
            phone: '0123456789',
            username: nameMob,
            password: passwordMob,
            gender: genderMob,
        });
  
          if (response.status === 200) {
            console.log(response.data);
            console.log('Request successful');
            setUser({
              name: nameMob,
              email: emailMob,
              phone: '0123456789',
              gender: genderMob,
              currGender: genderMob,
              password: passwordMob,

            });
            setCurrGen({
              currGender: genderMob
            })
  
            navigate("/home");
          }
          else if (response.status === 201) {
            alert('Email already exists')
          } else {
            console.log('Request failed', response.data.message);
          }
        } catch (error) {
          console.log('An error occurred:', error);
        }
      }
  };

  const handleLogin = () => {
    navigate("/");
  };  

  return (
    <div className="w-full min-h-fit bg-[#F8E6EA]">
      {/* D E S K T O P */}
      <div className="w-full min-h-fit h-screen flex xxs:hidden tab:flex flex-row bg-[#F8E6EA]">
        <img
          src={logobg}
          alt="face image"
          className="w-[45rem] xl:w-[50%] h-full"
        />
        <div className="h-full min-h-fit w-[50%] flex flex-col items-center justify-start bg-[#F8E6EA]">
          <div className="w-[90%] xl:w-[85%] h-fit flex flex-col items-center mt-5">
            <div className="w-full flex flex-row justify-between items-center">
              <img src={logo} alt="company logo" className="h-12" />
              <div className="flex flex-row items-center justify-end text-sm lg:text-base">
                Have an account?
                <div
                  onClick={handleLogin}
                  className="mx-1 lg:mx-2 xl:mx-5 font-medium cursor-pointer"
                >
                  Log In!
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center text-3xl font-medium">
              Welcome to Dermo Iterve
            </div>
            <div className="w-full flex justify-center mt-3">
              Getting started is easy
            </div>
            <form
              className="w-[60%] h-fit flex flex-col items-center justify-center mt-3"
              onSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col">
                <label className="font-medium">Full Name</label>
                <input
                  id="name"
                  type="text"
                  required
                  autoComplete="name"
                  onChange={(e) => setName(e.target.value)}
                  className="w-full h-12 mt-2 flex bg-white shadow-cs1 rounded-lg px-4"
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="font-medium">Enter Email</label>
                <input
                  id="email"
                  type="email"
                  required
                  autoComplete="email"
                  onChange={handleEmailChange}
                  className="w-full h-12 mt-2 flex bg-white shadow-cs1 rounded-lg px-4"
                />
              </div>
              <div className="w-full flex flex-col mt-4 relative">
                <label className="font-medium">Enter Password</label>
                <input
                  id="password"
                  type={isPasswordVisible ? "text" : "password"}
                  required
                  autoComplete="password"
                  onChange={handlePasswordChange}
                  className="w-full h-12 mt-2 flex bg-white shadow-cs1 rounded-lg px-4"
                />
                {isPasswordVisible ? (
                  <svg
                    onClick={toggleShowHide}
                    className="absolute top-[59%] right-2 transform h-6 w-6 cursor-pointer text-black opacity-95 z-[6]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    <path
                      fillRule="evenodd"
                      d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={toggleShowHide}
                    className="absolute top-[59%] right-2 transform h-6 w-6 cursor-pointer text-black opacity-95 z-[6]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                    <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                    <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                  </svg>
                )}
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="font-medium">Confirm Password</label>
                <input
                  id="confpassword"
                  type={isPasswordVisible ? "text" : "password"}
                  required
                  autoComplete="password"
                  onChange={(e) => setCnfpassword(e.target.value)}
                  className="w-full h-12 mt-2 flex bg-white shadow-cs1 rounded-lg px-4"
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="font-medium">Select Gender</label>
                <select
                  style={{
                    WebkitAppearance: "none",
                  }}
                  id="genderMob"
                  required
                  className="w-full h-12 mt-2 flex bg-white shadow-cs1 rounded-lg px-4"
                  value={gender}
                  onChange={handleGenderChange}
                >
                  <option className="!text-gray-700" value="" disabled>Select</option>
                  <option className="text-black" value="Male">Male</option>
                  <option className="text-black" value="Female">Female</option>
                </select>
              </div>
              {/* <div className="w-full mt-3 hidden">
                <label className="inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" />
                  <div className="relative w-9 h-5 border border-cbg3 bg-cbg4 rounded-full peer dark:bg-cbg4 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[.08rem] after:start-[.06rem] after:bg-cbg2 after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-cbg3 peer-checked:bg-cbg2 peer-checked:after:bg-cbg4"></div>
                  <span className="ms-3 text-sm font-medium text-gray-700 dark:text-cbg2">
                    Remember me
                  </span>
                </label>
              </div> */}
              <button type='submit' className="w-full h-14 mt-4 bg-cbg2 text-white rounded-lg focus:scale-[95%] hover:scale-[95%] transition-all duration-300 ease-in-out">
                SIGN UP
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* T A B */}
      <div className="w-full h-screen min-h-fit flex xxs:hidden tabPotrait:flex tab:hidden flex-row bg-[#F8E6EA]">
        <img src={logobgtab} alt="face image" className="w-[52%] h-auto" />
        <div className="h-full min-h-fit w-[65%] flex flex-col items-center justify-start bg-[#F8E6EA] mb-10">
          <div className="w-[90%] h-fit flex flex-col items-center mt-5">
            <div className="w-full flex flex-row justify-between items-center">
              <img src={logo} alt="company logo" className="h-12" />
              <div className="flex flex-row items-center justify-end text-sm lg:text-base">
                Have an account?
                <div
                  onClick={handleLogin}
                  className="mx-5 font-medium cursor-pointer"
                >
                  Log In!
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center text-4xl font-medium mt-3">
              Welcome to Dermo Iterve
            </div>
            <div className="w-full flex justify-center mt-6">
              Getting started is easy
            </div>
            <form
                onSubmit={handleSubmit}
                className="w-[80%] flex flex-col items-center justify-center mt-3"
            >
              <div className="w-full flex flex-col">
                <label className="font-medium">Full Name</label>
                <input
                  id="nameTab"
                  type="text"
                  required
                  autoComplete="name"
                  onChange={(e) => setName(e.target.value)}
                  className="w-full h-14 mt-4 flex bg-white shadow-cs1 rounded-lg px-4"
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="font-medium">Enter Email</label>
                <input
                  id="emailTab"
                  type="email"
                  required
                  autoComplete="email"
                  onChange={handleEmailChange}
                  className="w-full h-14 mt-4 flex bg-white shadow-cs1 rounded-lg px-4"
                />
              </div>
              <div className="w-full flex flex-col mt-4 relative">
                <label className="font-medium">Enter Password</label>
                <input
                  id="passwordTab"
                  type={isPasswordVisible ? "text" : "password"}
                  required
                  autoComplete="password"
                  onChange={handlePasswordChange}
                  className="w-full h-14 mt-4 flex bg-white shadow-cs1 rounded-lg px-4 pr-12"
                />
                {isPasswordVisible ? (
                  <svg
                    onClick={toggleShowHide}
                    className="absolute top-[59%] right-2 transform h-6 w-6 cursor-pointer text-black opacity-95 z-[6]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    <path
                      fillRule="evenodd"
                      d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={toggleShowHide}
                    className="absolute top-[59%] right-2 transform h-6 w-6 cursor-pointer text-black opacity-95 z-[6]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                    <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                    <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                  </svg>
                )}
              </div>
              <div className="w-full flex flex-col mt-4 relative">
                <label className="font-medium">Confirm Password</label>
                <input
                  id="confPasswordTab"
                  type={isPasswordVisible ? "text" : "password"}
                  required
                  autoComplete="password"
                  onChange={(e) => setCnfpassword(e.target.value)}
                  className="w-full h-14 mt-4 flex bg-white shadow-cs1 rounded-lg px-4"
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="font-medium">Select Gender</label>
                <select
                  style={{
                    WebkitAppearance: "none",
                  }}
                  id="genderMob"
                  required
                  className="w-full h-14 mt-4 flex bg-white shadow-cs1 rounded-lg px-4"
                  value={gender}
                  onChange={handleGenderChange}
                >
                  <option className="!text-gray-700" value="" disabled>Select</option>
                  <option className="text-black" value="Male">Male</option>
                  <option className="text-black" value="Female">Female</option>
                </select>
              </div>
              {/* <div className="w-full mt-3 hidden">
                <label className="inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" />
                  <div className="relative w-9 h-5 border border-cbg3 bg-cbg4 rounded-full peer dark:bg-cbg4 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[.08rem] after:start-[.06rem] after:bg-cbg2 after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-cbg3 peer-checked:bg-cbg2 peer-checked:after:bg-cbg4"></div>
                  <span className="ms-3 text-sm font-medium text-gray-700 dark:text-cbg2">
                    Remember me
                  </span>
                </label>
              </div> */}
              <button className="w-full h-14 mt-4 bg-cbg2 text-white rounded-lg focus:scale-[95%] hover:scale-[95%] transition-all duration-300 ease-in-out select-none">
                SIGN UP
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* M O B I L E */}
      <div className="w-full min-h-fit h-screen flex tabPotrait:hidden flex-col bg-white">
        <img src={logobgmob} alt="face image" className="h-fit" />
        <div className="h-full w-full flex flex-col items-center justify-start">
          <div className="w-full  h-fit flex flex-col items-start mt-2 px-[5%]">
            <div className="w-full flex text-2xl font-medium mt-2">
              Welcome to Dermo Iterve
            </div>
            <div className="w-full flex mt-2 text-gray-500">
              Getting started is easy
            </div>
            <form 
                onSubmit={handleSubmitMob}
                className="w-full flex flex-col items-center justify-center mt-3 text-gray-700"
            >
              <div className="w-full flex flex-col">
                <label>Full Name</label>
                <input
                  id="nameMob"
                  type="text"
                  required
                  autoComplete="name"
                  // placeholder="John Doe"
                  onChange={(e) => setNameMob(e.target.value)}
                  className="w-full h-14 mt-3 flex bg-white border border-brbg1 rounded-lg px-4 placeholder:text-tbg1"
                />
              </div>
              <div className="w-full flex flex-col mt-3">
                <label>Enter Email</label>
                <input
                  id="emailMob"
                  type="email"
                  required
                  autoComplete="email"
                  // placeholder="abc@outlook.com"
                  onChange={handleEmailChangeMob}
                  className="w-full h-14 mt-3 flex bg-white border border-brbg1 rounded-lg px-4 placeholder:text-tbg1"
                />
              </div>
              <div className="w-full flex flex-col mt-3 relative">
                <label>Enter Password</label>
                <input
                  id="passwordMob"
                  type={isPasswordVisibleMob ? "text" : "password"}
                  required
                  autoComplete="password"
                  // placeholder="XXXX"
                  onChange={handlePasswordChangeMob}
                  className="w-full h-14 mt-3 flex bg-white border border-brbg1 rounded-lg px-4 pr-12 placeholder:text-tbg1"
                />
                {isPasswordVisibleMob ? (
                  <svg
                    onClick={toggleShowHideMob}
                    className="absolute top-[59%] right-2 transform h-6 w-6 cursor-pointer text-black opacity-95 z-[6]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    <path
                      fillRule="evenodd"
                      d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={toggleShowHideMob}
                    className="absolute top-[59%] right-2 transform h-6 w-6 cursor-pointer text-black opacity-95 z-[6]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                    <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                    <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                  </svg>
                )}
              </div>
              <div className="w-full flex flex-col mt-3">
                <label>Confirm Password</label>
                <input
                  id="confPasswordMob"
                  type={isPasswordVisibleMob ? "text" : "password"}
                  required
                  autoComplete="password"
                  // placeholder="XXXX"
                  onChange={(e) => setCnfpasswordMob(e.target.value)}
                  className="w-full h-14 mt-3 flex bg-white border border-brbg1 rounded-lg px-4 placeholder:text-tbg1"
                />
              </div>
              <div className="w-full flex flex-col mt-3">
                <label>Select Gender</label>
                <select
                  style={{
                    WebkitAppearance: "none",
                  }}
                  id="genderMob"
                  required
                  className="w-full h-14 mt-3 flex bg-white border border-brbg1 rounded-lg px-4 placeholder:text-tbg1"
                  value={gender}
                  onChange={handleGenderChange} 
                >
                  <option className="!text-gray-700" value="" disabled>Select</option>
                  <option className="text-black" value="Male">Male</option>
                  <option className="text-black" value="Female">Female</option>
                </select>
              </div>
              {/* <div className="w-full mt-4 hidden">
                <label className="inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" />
                  <div className="relative w-9 h-5 border border-cbg3 bg-cbg4 rounded-full peer dark:bg-cbg4 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[.08rem] after:start-[.06rem] after:bg-cbg2 after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-cbg3 peer-checked:bg-cbg2 peer-checked:after:bg-cbg4"></div>
                  <span className="ms-3 text-sm font-medium text-gray-700 dark:text-cbg2">
                    Remember me
                  </span>
                </label>
              </div> */}
              <button className="w-full h-14 mt-3 bg-cbg2 text-white rounded-lg focus:scale-[95%] hover:scale-[95%] transition-all duration-300 ease-in-out select-none">
                SIGN UP
              </button>
            </form>
            <div className="w-full flex flex-row justify-center mt-3 mb-5 text-cbg2">
              Have an account?&nbsp;
              <div
                onClick={handleLogin}
                className="text-black font-medium cursor-pointer"
              >
                Log In!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
