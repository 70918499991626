import React from "react";
import styles from "./Contact.module.css"; // CSS Module
import client from "../images/client.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import insta from "../images/Instagram.png";
import linkedin from "../images/LinkedIn.png";
import fb from "../images/Facebook.png";
import aivot from "../images/aivot.png";
import { useNavigate } from "react-router-dom";
import profile from "../images/profile.png";
import axios from "axios";
import { useState } from "react";
import { Link } from 'react-router-dom';

const Contact = () => {
  const navigate = useNavigate();
  const navigatePath = (path) => {
    navigate(path);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // Handle form inputs
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from refreshing the page

    try {
      // Send POST request to the backend
      const response = await axios.post(
        "https://dermo.iterve.ai/contactdetails/submit-form",
        formData
      );
      console.log(response.data); // Log response from the server
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };

  return (
    <div className={styles.outer}>
      <div className="h-14 w-full px-3 py-3 md:py-2 flex xxs:hidden tabPotrait:flex flex-row justify-between items-center bg-white">
        <img src={aivot} alt="logo" className="h-10 select-none" />
        <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/home")}
          >
            Home
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/test")}
          >
            Test
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/contact")}
          >
            Contact Us
          </div>
        </div>

        <img src={profile} alt="profile" className="h-8 px-6 cursor-pointer" onClick={() => navigatePath('/profile')}/>
      </div>
      <div className="h-16 w-full px-3 py-3 md:py-2 flex tabPotrait:hidden flex-row justify-between items-center bg-white">
                    <img src={aivot} alt="logo" className="h-10 select-none" />
                    <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/home')}>Home</div>
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/test')}>Test</div>
                        <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/contact')}>Contact Us</div>
                    </div>
                    <img src={profile} alt="profile" className="h-8 px-6 cursor-pointer" onClick={() => navigatePath('/profile')}/>
        </div>

      <div className={styles.container}>
        <div className={styles.imageSection}>
          <img src={client} alt="client" className={styles.client} />
        </div>
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Keep In Touch</h2>
          <p className={styles.subtitle}>
            We prioritize responding to your inquiries promptly to ensure you
            receive the assistance you need in a timely manner.
          </p>
          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              className={styles.input}
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className={styles.input}
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone (+91)"
              className={styles.input}
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              className={styles.textarea}
              value={formData.message}
              onChange={handleInputChange}
              required
            />
            <button type="submit" className={styles.button}>
              Send Message
            </button>
          </form>
        </div>
      </div>

      {/* Updated container2 */}
      <div className={styles.container2}>
        <div className={styles.head1}>INDIA (HQ)</div>
        <div className={styles.head2}>
          We love to connect over email/phone or meet in person
        </div>
        <div className={styles.head3}>
          We are eager to connect to understand your business need and built an
          innovative AI powered solution together
        </div>
        {/* Contact Information */}
        <div className={styles.contactItem}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
          <div className={styles.location}>
            1609-1610, Kamdhenu Commerz, Sector - 14, Kharghar, Navi Mumbai -
            410210, India
          </div>
        </div>
        <div className={styles.contactItem}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
          <div className={styles.location}>
            Module-G03, Webel Tower-II, Block - BN, Salt lake Sector-V, P.S-
            Electronics Complex, Kolkata, pin-700091, India
          </div>
        </div>
        <div className={styles.contactItem}>
          <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
          <div className={styles.location}>info@aivot.in</div>
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footcontainer}>
          {/* Left Section: Logo and Description */}
          <div className={styles.leftSection}>
            <img src={aivot} alt="Aivot Logo" className={styles.logo} />
            <p className={styles.description}>
              Dermo Iterve is an advanced AI-powered skin analysis platform that
              provides instant insights into your skin's health. With a simple
              10-second face video, you can detect skin conditions, monitor for
              early signs of skin cancer, and receive personalized skincare
              recommendations.
            </p>
            <p className={styles.description2}>Follow us</p>
            <div className={styles.socialIcons}>
              <a href="#facebook" className={styles.icon}>
                <img src={fb} alt="fb" />
              </a>
              <a href="#instagram" className={styles.icon}>
                <img src={insta} alt="insta" />
              </a>
              <a href="#linkedin" className={styles.icon}>
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>

          {/* Middle Section: Links */}
          <div className={styles.middleSection}>
            <ul className={styles.navLinks}>
            <li>
          <Link to="/home">Home</Link>
        </li>
        <li>
          <Link to="/test">Take Test</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
            </ul>
            <p className={styles.copyr}>
              Copyright &copy; 2024 AIVOT - All Rights Reserved.
            </p>
          </div>

          {/* Right Section: Newsletter Subscription */}
          <div className={styles.rightSection}>
            <h3 className={styles.subscribeTitle}>Subscribe to Newsletter</h3>
            <form className={styles.subscribeForm}>
              <input
                type="email"
                placeholder="Enter your email"
                className={styles.emailInput}
              />
              <button type="submit" className={styles.subscribeButton}>
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className={styles.mobilecopyr}>
          Copyright &copy; 2024 AIVOT - All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Contact;
