import React from "react";
import logo from '../images/logo.png'
 
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";

// Font.register({
//     family: 'Oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
// });


const styles = StyleSheet.create({
	container: {
      letterSpacing: '0.35vw',
      padding: "3%",
      height: "100%",
      width: "100%",
      backgroundColor: "#E5F0F3"
    },
    insideCont: {
      height: '100%',
      width: '100%',
      padding: '5vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '2px solid #6c6c6c'
    },
    dataCont: {
      borderRadius: '1.25vw',
      borderRight: '1px solid #525252',
      borderBottom: '2px solid #525252',
      marginTop: '1.5vw',
      padding: '5vw',
      height: '80%',
      width: '100%',
      backgroundColor: '#fff'
    },
    dataCont2: {
      padding: '3vw 5vw 3vw 5vw',
      borderRadius: '1.25vw',
      height: '100%',
      width: '100%',
      border: '1px solid black',
      justifyContent: 'space-between'
    },
  	header: {
      padding: '1vw',
      fontSize: '4.5vw',
      fontWeight: 'bold',
      color: '#1E1E1E'
    },
  	subHeader: {
      padding: '1vw',
      fontSize: '3vw',
      fontWeight: 'bold',
      color: '#4B4B4B'
    },
  	image: {
      height: '8.5vw'
    },
    topCont: {
      width: '100%',
      paddingBottom: '1.5vw',
      borderBottom: '1px dashed #B8B0B0'
    },
    nameCont: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '1vw',
      fontSize: '2vw',
      color: '#525252'
    },
    nameTextBold: {
      fontWeight: 'bold',
      color: '#000'
    },
    bottomCont: {
      width: '100%',
      height: '74%',
      gap: '2vw',
    },
  	resCont: {
        borderRadius: '1vw',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '1vw',
      border: '1px solid #525252',
      borderBottom: '2px solid #525252',
      borderRight: '2px solid #525252',
      color: '#525252',
      fontSize: '2vw'
    }
});

const MyPDF = ({result, name, age, gender, date}) => {
    const res = [
        { label: 'Face Shape', value: result.face_shape },
        { label: 'Skin Tone', value: result.skin_tone },
        { label: 'Hair Type', value: result.hair_type.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') },
        { label: 'Eye Shape', value: result.eye_shape.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') },
        { label: 'Dark Circle', value: result.dark_circles },
        { label: 'Skin Type', value: result.skin_type },
        { label: 'Wrinkles', value: result.wrinkles === 'wrinkles' ? 'Yes' : 'No' },
    ];

   
    if (result.skin_disease !== 'clear skin') {
        res.push({ label: 'Skin Disease', value: result.skin_disease });
    } else {
        res.push({ label: 'Skin Disease', value: 'No Disease' });
    }
    // Conditionally set backgroundColor based on gender
    const genderBasedStyle = {
        ...styles.container,
        backgroundColor: gender === 'Male' ? '#E5F0F3' : '#F8E6EA',
    };

    return (
        <Document>
            <Page size={"A4"}>
                <View style={genderBasedStyle}>
                    <View style={styles.insideCont}>
                      <Image src={logo} style={styles.image} />
                      <Text style={styles.header}>Dermo Iterve</Text>
                      <Text style={styles.subHeader}>Skin Test Result Report</Text>
                      <View style={styles.dataCont}>
                      	<View style={styles.dataCont2}>
                          <View style={styles.topCont}>
                          	<View style={styles.nameCont}>
                            	<Text style={styles.nameTextBold}>Name</Text>
                                <Text>{name}</Text>
                            </View>
                          	<View style={styles.nameCont}>
                            	<Text style={styles.nameTextBold}>Age</Text>
                                <Text>{age} Years</Text>
                            </View>
                          	<View style={styles.nameCont}>
                            	<Text style={styles.nameTextBold}>Gender</Text>
                                <Text>{gender}</Text>
                            </View>
                          	<View style={styles.nameCont}>
                            	<Text style={styles.nameTextBold}>Tested on</Text>
                                <Text>{date}</Text>
                            </View>
                          </View>
                          <View style={styles.bottomCont}>
                            {/* Loop through the result data */}
                            {res.map((item, index) => (
                              <View style={styles.resCont} key={index}>
                                  <Text>{item.label}</Text>
                                  <Text style={styles.nameTextBold}>{item.value}</Text>
                              </View>
                            ))}
                          </View>
                        </View>
                      </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default MyPDF;